import { Injectable, signal } from "@angular/core";
import { TPlatformTheme } from "../utilities/types";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private readonly currentTheme = signal<TPlatformTheme>("light");
  constructor() {
    this.checkCurrentTheme();
  }

  get currentPlatformTheme() {
    return this.currentTheme.asReadonly();
  }
  changePlatformTheme(): void {
    const nextTheme: TPlatformTheme =
      this.currentTheme() === "light" ? "dark" : "light";
    this.setPlatformTheme(nextTheme);
  }
  /**
   * Sets the cookie for the platform theme.
   * @param theme Defines the theme being set. Defaults to **light** if not defined.
   */
  private setPlatformTheme(theme: TPlatformTheme): void {
    document.cookie = `horizon_theme=${theme};path=/;max-age=2592000;Secure;SameSite=Strict`;
    document.body.classList[theme === "light" ? "remove" : "add"](
      "theme-alternate"
    );
    this.currentTheme.set(theme);
  }

  private checkCurrentTheme() {
    const cookies = document.cookie.split(";");
    const foundIndex = cookies.findIndex((cookie) =>
      cookie.includes(`horizon_theme`)
    );
    if (foundIndex === -1) return;
    this.setPlatformTheme(this.extractThemeData(cookies[foundIndex]));
  }

  private extractThemeData(rawCookieData: string): TPlatformTheme {
    const extractedValue = rawCookieData.split("=")[1];
    if (extractedValue !== "light" && extractedValue !== "dark") return "light";
    return extractedValue;
  }
}
