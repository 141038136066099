<div
  class="generic-box flex-column"
  [style.gap.px]="gapSize"
  [ngClass]="[
    theme + '-theme',
    positioning() + '-positioning',
    background() + '-background'
  ]"
>
  @if (loaderText !== '') {
  <span [style.font-size.px]="fontSize">{{ loaderText }}</span>
  }
  <mat-progress-bar
    class="loading-bar"
    [ngClass]="theme + '-theme'"
    mode="buffer"
  />
</div>
