import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { KeycloakBearerInterceptor, KeycloakService } from "keycloak-angular";
import { AppComponent } from "./app.component";
import { AuthService } from "./auth/auth.service";
import { AppRoutingModule } from "./app.routing";
import { IconsModule } from "./core/icons/icons.module";
import { SharedModule } from "./modules/shared/shared.module";
import {
  MatPaginatorIntl,
  MAT_PAGINATOR_DEFAULT_OPTIONS,
} from "@angular/material/paginator";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { customPaginator } from "./modules/shared/utilities/utils";
import { MonacoEditorModule } from "ngx-monaco-editor-v2";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    IconsModule,
    SharedModule,
    MonacoEditorModule.forRoot(),
  ],
  providers: [
    AuthService,
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: "outline",
        showFirstLastButtons: true,
        pageSizeOptions: [5, 10, 15],
      },
    },
    {
      provide: MatPaginatorIntl,
      useValue: customPaginator(),
    },
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () =>
        authService.initializeKeycloak(),
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline", subscriptSizing: "dynamic" },
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
